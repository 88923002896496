<template>
  <ul>
    <li class="login-tip" @click="gotoReg">{{ message }}</li>
    <li
      class="login-tip"
      @click="toBuddhism"
      style="text-align: right; padding-right: 2.778vw"
      v-if="this.$store.state.isLogin"
    >
      返回首页
    </li>
  </ul>
</template>

<script>

export default ({
  props: ['message'],
  methods: {
    gotoReg () {
      this.$emit('gotoReg')
    },
    toBuddhism () {
      this.$emit('toBuddhism')
    }
  }
})
</script>

<style lang="scss" scoped>
a {
  color: #42b983;
}
.login-tip {
  display: inline;
  margin-top: 2.778vw;
  font-size: 2vw;
  line-height: 6.111vw;
  color: #1ab2ff;
  cursor: pointer;
  text-align: left;
  //   text-indent: 2.222vw;
  width: 50%;
}
.login:hover {
  color: #2c2fd6;
}
ul {
  display: flex;
  li {
    flex: 1;
    line-height: 5.556vw;
  }
}
</style>
