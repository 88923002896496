<template>
  <div id="login">
    <FormTop titleTop="注册"> </FormTop>

    <inputMine
      placeholder="用户名"
      type="text"
      rule="^.{1,16}$"
      style="margin: 4.157vm 0"
      @inputChange="(res) => (usermodel.username = res)"
      inputName="username"
      errmsg="帐号为空1-16位"
      ifVerifyOnline
    >
    </inputMine>
    <inputMine
      placeholder="电话号码"
      type="tel"
      rule="^1[0-9]{10}$"
      style="margin: 4.157vm 0"
      @inputChange="(res) => (usermodel.phone = res)"
      inputName="phone"
      errmsg="电话不能为空或格式不正确"
      ifVerifyOnline
    >
    </inputMine>

    <inputMine
      placeholder="密码"
      type="password"
      rule="^.{6,16}$"
      style="margin: 4.157vm 0"
      @inputChange="(res) => (usermodel.password = res)"
      inputName="password"
      errmsg="密码在6-16位之间"
    >
    </inputMine>
    <inputMine
      placeholder="确认密码"
      type="password"
      rule="^.{6,16}$"
      style="margin: 4.157vm 0"
      @inputChange="(res) => (usermodel.repassword = res)"
      inputName="repassword"
      errmsg="密码在6-16位之间"
    >
    </inputMine>
    <inputMine
      placeholder="单击图片换一张"
      type="text"
      rule="^.{1,16}$"
      @captchaChange="captchaChange"
      inputName="captcha"
      ifVerifyOnline
      errmsg="验证码不能为空"
    >
      <img :src="src" height="30px" width="79.999px" @click="getCodeSrc" />
    </inputMine>

    <fomrBtn @submitClick="submitClick" btnText="注册"></fomrBtn>
    <LoginBottom
      message="已有账号？立即登录"
      @gotoReg="gotoLogin()"
      @toBuddhism="gotoBuddhism()"
    ></LoginBottom>
  </div>
</template>

<script>
// import { Toast } from 'mint-ui'
import { mapState } from 'vuex'

import FormTop from './common/FormTop.vue'
import inputMine from './common/inputMine.vue'
import fomrBtn from './common/formBtn.vue'
import LoginBottom from './common/Loginbottom.vue'

export default {

  components: { FormTop, inputMine, fomrBtn, LoginBottom },

  data () {
    return {
      usermodel: {
        username: '',
        password: '',
        phone: '',
        repassword: ''
      },
      captcha: '',
      src: '',
      state: '',
      ifVerifyOnline: true
    }
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  },
  mounted () {
    this.src = 'https://api.sila.ink/api.php/Login/verify'
  },
  methods: {
    ...mapState(['isLogin']),
    captchaChange (state) {
      this.state = state
      //   console.log(this.state)
    },
    // <!--提交登录-->
    submitClick () {
      if (this.validateUsername() && this.validateCaptcha() && this.passwordComfirm()) {
        console.log('请求注册')
        this.$store.dispatch('registerAction', this.usermodel)
      }
    },
    validateUsername () {
      //   console.log(this.usermodel.username, 'user---')
      if (this.usermodel.username) {
        return true
      } else {
        this.$msg({ message: '用户名不能为空' })
        return false
      }
    },
    validateCaptcha () {
      if (this.state) {
        return true
      } else {
        this.$msg({ message: '验证码不正确' })
        return false
      }
    },
    passwordComfirm () {
      if (!this.usermodel.password) {
        this.$msg({ message: '密码不能为空' })
        return false
      }
      if (!this.usermodel.repassword) {
        this.$msg({ message: '先确认密码' })
        return false
      }
      if (this.usermodel.password !== this.usermodel.repassword) {
        this.$msg({ message: '两次输入的密码不一致' })
        return false
      }
      return true
    },
    // <!--进入登录页-->
    gotoLogin () {
      this.$router.push({
        path: '/login'
      })
    },
    gotoBuddhism () {
      this.$store.commit('hideShowTabbar', true)
      this.$router.push({
        path: '/buddhism'
      })
    },
    buddhism (path) {
      this.$router.push(path)
      this.$store.commit('hideShowTabbar', true)
    },
    getCodeSrc () {
      this.src = 'https://api.sila.ink/api.php/Login/verify?' + Math.random()
    }
  },
  beforeUnmount () {
    this.$store.commit('hideShowTabbar', false)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
body {
  background-color: #42b983;
}

#login {
  max-width: 94.444vw;
  margin: 16.667vw auto;
  background: #fff;
  padding: 5.556vw 11.111vw;
  border-radius: 2.778vw;
  position: relative;
  z-index: 9;
}
.title {
  font-size: 7.222vw;
  line-height: 13.889vw;
  font-weight: bold;
  margin: 2.778vw;
  text-align: center;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 2.778vw;
}
a {
  color: #42b983;
}
.login-tip {
  display: inline;
  margin-top: 2.778vw;
  font-size: 2vw;
  line-height: 6.111vw;
  color: #1ab2ff;
  cursor: pointer;
  text-align: left;
  //   text-indent: 2.222vw;
  width: 50%;
}
.login:hover {
  color: #2c2fd6;
}
ul {
  display: flex;
  li {
    flex: 1;
    line-height: 5.556vw;
  }
}
</style>
