<template>
  <div class="btnBox" @click="submitClick">
    <div class="submitbtn">{{ btnText }}</div>
  </div>
</template>
<script>

export default ({
  props: {
    btnText: {
      type: String,
      default: '登录'
    }
  },
  methods: {
    submitClick () {
      this.$emit('submitClick')
    }
  }
})
</script>

<style lang="scss" scoped>
.btnBox {
  padding: 4.167vw 2.778vw;
}
.submitbtn {
  height: 12.5vw;
  background-color: coral;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4.444vw;
  border-radius: 6.25vw;
}
</style>
