<template>
  <div>
    <div>
      <img src="@/assets/logo.png" alt="" @click="$router.push('/')" />
    </div>
    <div class="formtop-flex">
      <div class="formTop-left"></div>
      <div class="formTop-mid">{{ titleTop }}</div>
      <div class="formTop-right"></div>
    </div>
  </div>
</template>
<script>

export default ({
  props: [
    'titleTop'
  ]
})
</script>

<style lang="scss" scoped>
.formtop-flex {
  display: flex;
  background-color: #fff;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4.444vw;
  }
}
.formTop-mid {
  height: 13.889vw;
  width: 100%;
  line-height: 13.889vw;
}
img {
  width: 22.222vw;
  margin: 0vw;
  padding: 0vw;
}
</style>
