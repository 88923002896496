<template>
  <div id="content">
    <Reg></Reg>
  </div>
</template>

<script>
import Reg from '@/components/Reg'
export default {
  components: {
    Reg
  }
}
</script>

<style lang="scss" scoped>
#content {
  text-align: center;
  margin: auto;
  width: 100%;
  height: 188.889vw;
}
</style>
