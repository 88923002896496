<template>
  <div>
    <mt-field
      :label="label"
      :inputName="inputName"
      :ifVerifyOnline="ifVerifyOnline"
      :placeholder="placeholder"
      :type="type"
      :state="state"
      :rule="rule"
      :disableClear="disableClear"
      :readonly="readonly"
      :disabled="disabled"
      :attr="attr"
      v-model="content"
      :errmsg="errmsg"
      @blur.native.capture="handelBlur"
      @focus.native.capture="handelFocus"
    >
      <slot></slot>
    </mt-field>
  </div>
</template>

<script>
import Vue from 'vue'
const api = new Vue()
export default ({
  props: {
    label: {
      type: String,
      default: ''
    },
    inputName: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    rule: {
      type: String,
      default: ''
    },
    errmsg: {
      type: String,
      default: ''
    },
    disableClear: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    ifVerifyOnline: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    attr: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      content: '',
      state: ''
    }
  },
  methods: {
    handelFocus () {
      this.state = ''
    },
    async handelBlur () {
      const regs = new RegExp(this.rule)
      console.log(regs.test(this.content), 'test')
      console.log(this.content)
      if (regs.test(this.content)) {
        // console.log(this.inputName)
        if (this.inputName === 'captcha' && this.ifVerifyOnline) {
          const res = await api.$api.login.verify({
            code: this.content,
            key: Math.random()
          })
          if (res.data.status) {
            this.state = 'success'
            this.$emit('captchaChange', res.data.status)
          } else {
            this.state = 'error'
          }
        } else if (this.inputName === 'username' && this.ifVerifyOnline) {
          const res = await api.$api.register.isRegistered({
            username: this.content
          })
          if (res.data.status) {
            this.state = 'success'
            this.$emit('userNamerChk', res.data.status)
          } else {
            this.state = 'error'
            this.$msg({ message: res.data.msg })
          }
        } else if (this.inputName === 'phone') {
          this.state = 'success'
        } else {
          this.state = 'success'
        }
        this.$emit('inputChange', this.content)
      } else {
        this.state = 'error'
        this.$msg({ message: this.errmsg })
      }
    }
  }
})
</script>
